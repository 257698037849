<template>
  <div>
    <div class="page-wrapper-table">
      <h3>{{ $t("WALLET.TRANSACTIONS_LIST") }}</h3>
      <div class="page-wrapper-table-header">
        <el-select
          class="select-primary pagination-select"
          v-model="pagination.perPage"
          :placeholder="$t('WALLET.PER_PAGE')"
        >
          <el-option
            class="select-primary"
            v-for="item in pagination.perPageOptions"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
        <organization-selector
          v-if="showGlobal &&
            $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)
          "
          @organizationChanged="
            (organizationId) => {
              selectedOrganization = organizationId
            }
          "
        />
        <base-input>
          <el-select
            :label="$t('WALLET.STATUS')"
            :placeholder="$t('WALLET.STATUS')"
            v-model="selectedStatut"
            @change="
              (status) => {
                selectedStatut = status;
              }
            "
          >
            <el-option
              v-for="(value, key) in TRANSACTION_STATUS_LIST"
              :key="key"
              :value="value"
              :label="value ? $t(`WALLET.${value}`) : ''"
            />
          </el-select>
        </base-input>
        <base-input>
          <el-select
            :label="$t('COMMON.TYPE')"
            :placeholder="$t('COMMON.TYPE')"
            v-model="selectedType"
            @change="
              (type) => {
                selectedType = type;
              }
            "
          >
            <el-option
              v-for="(value, key) in TRANSACTION_TYPES"
              :key="key"
              :value="value"
              :label="value ? $t(`WALLET.${value}`) : ''"
            />
          </el-select>
        </base-input>
        <base-input
          v-model="query"
          type="search"
          :class="showGlobal?'':'w-100'"
          prepend-icon="fas fa-search"
          :placeholder="$t('WALLET.SEARCH')"
          clearable
        />
        
        <base-button
          v-if="showGlobal"
          class="btn base-button notification bg-dark btn-icon btn-fab btn-info btn-sm mb-4"
          icon
          size="sm"
          @click="resetFilters"
        >
          <span class="btn-inner--icon">
            <i class="fa fa-undo"></i>
          </span>
        </base-button>
      </div>
      <div class="page-wrapper-table-body">
        <div class="page-wrapper-table-body-inner">
          <el-table
            class="align-items-center table-flush"
            header-row-class-name="thead-light"
            :data="transactions"
            @sort-change="sortChange"
          >
            <div class="table-loading" slot="empty" v-if="loading">
              <img src="/img/loading.gif" />
            </div>
            <el-table-column
              :label="$t('WALLET.NUMERO')"
              prop="code"
              sortable="custom"
              min-width="150"
            >
              <template v-slot="{ row }">
                <span>
                  {{ row.meta.code ?? "" }}
                </span>
              </template>
            </el-table-column>
            <el-table-column :label="null" prop="status" min-width="70">
              <template v-slot:header>
                <ArrowsUpDown />
              </template>
              <template v-slot="{ row }">
                <in-out-badge :type="row.type" />
              </template>
            </el-table-column>

            <el-table-column
              :label="$t('WALLET.TRANSACTION_STATUS')"
              prop="status"
              min-width="200"
            >
              <template v-slot="{ row }">
                <el-tooltip
                  v-if="row.meta.status_comment"
                  :content="row.meta.status_comment"
                  placement="top"
                >
                <div class="d-f">
                  <status-badge :status="row.meta.status" />
                  <i class="fa fa-info-circle ml-1 text-danger"></i>
                </div>
                </el-tooltip>
                <status-badge v-else :status="row.meta.status" />
              </template>
            </el-table-column>

            <el-table-column
              :label="$t('WALLET.TRANSACTION_AMOUNT')"
              prop="amount"
              sortable="custom"
              min-width="150"
            >
              <template v-slot="{ row }">
                <amount-badge :amount="row.amount" />
              </template>
            </el-table-column>


            <el-table-column
              :label="$t('COMMON.ORGANIZATION')"
              prop="status"
              min-width="220"
              v-if="walletId==null"
            >
              <template v-slot="{ row }">
                <span>{{ getWalletHolderName(row.wallet.holder) }}</span>
              </template>
            </el-table-column>
            
            <el-table-column
              :label="$t('WALLET.TRANSACTION_LABEL')"
              prop="label"
              sortable="custom"
              min-width="200"
            >
              <template v-slot="{ row }">
                <span>
                  {{ row.meta.label ?? "" }}
                </span>
              </template>
            </el-table-column>

            <!-- <el-table-column
              :label="$t('WALLET.TRANSACTION_AUTHOR')"
              prop="auth"
              min-width="200"
            >
              <template v-slot="{ row }">
                <span>
                  {{ row.meta.auth.name ?? "" }}
                </span>
              </template>
            </el-table-column> -->

            <el-table-column
              :label="$t('WALLET.TRANSACTION_CREATED_AT')"
              prop="created_at"
              sortable="custom"
              min-width="220"
            >
              <template v-slot="{ row }">
                {{ $formatDate(row.created_at) }}
              </template>
            </el-table-column>

            
            <el-table-column fixed="right" min-width="120">
              <div slot-scope="{ row }" class="table-actions">
                <el-tooltip
                  :content="$t('COMMON.VIEW')"
                  placement="top"
                  v-if="$currentUserCan($permissions.PERM_VIEW_FILES)"
                >
                  <a
                    type="text"
                    @click="viewTransaction(row.id)"
                    class="table-action"
                    data-toggle="tooltip"
                    style="cursor: pointer"
                  >
                    <i class="fal fa-expand-alt"></i>
                  </a>
                </el-tooltip>
              </div>
            </el-table-column>

          </el-table>
        </div>
      </div>
      <div slot="footer" class="page-wrapper-table-footer">
        <p class="card-category">
          {{
            $t("WALLET.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", {
              from: total ? from + 1 : 0,
              to: to,
              of: total,
            })
          }}
          <span v-if="selectedRows.length">
            &nbsp; &nbsp;
            {{ $t("WALLET.X_LINES_SELECTED", { count: selectedRows.length }) }}
          </span>
        </p>
        <base-pagination
          class="pagination-no-border"
          v-model="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="total"
        />
      </div>
    </div>
    <el-drawer
      :title="$t('WALLET.TRANSACTION')"
      :visible.sync="showTransactionDetails"
      direction="rtl"
      size="50%"
      append-to-body>
      <div class="transaction-details px-5">
        <view-transaction-component 
          :id="currentTransactionId"
          @transactionStatusUpdated="refreshData($event)"
        />
      </div>
    </el-drawer>

  </div>
</template>
<script>
import _ from "lodash";
import requestErrorMixin from "@/mixins/request-error-mixin";
import BasePagination from "../atoms/BasePagination.vue";
import { TRANSACTION_STATUS_LIST, STATUS_DRAFT,POSSIBLE_HOLDER_ATTRIBUTES, TRANSACTION_TYPES } from "../../constants/common";
import StatusBadge from "../atoms/StatusBadge.vue";
import InOutBadge from "../atoms/InOutBadge.vue";
import AmountBadge from "../atoms/AmountBadge.vue";
import ArrowsUpDown from "../../Icons/ArrowsUpDown.vue";
import ViewTransactionComponent from "../ViewTransactionComponent.vue";
import OrganizationSelector from "@/components/OrganizationSelector.vue";

export default {
  name: "TransactionsList",

  props: {
    walletId: {
      type: Number,
      default: null,
    },
    paymentGateway: {
      type: String,
      default: null,
    },
    showGlobal: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    BasePagination,
    StatusBadge,
    InOutBadge,
    AmountBadge,
    ArrowsUpDown,
    ViewTransactionComponent,
    OrganizationSelector
  },

  mixins: [requestErrorMixin],

  data() {
    return {
      query: null,
      selectedRows: [],
      sort: "-created_at",
      total: 0,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      transactions: [],
      loading: true,
      selectedStatut: null,
      selectedType: null,
      selectedOrganization: null,
      TRANSACTION_STATUS_LIST: TRANSACTION_STATUS_LIST,
      TRANSACTION_TYPES:TRANSACTION_TYPES,
      STATUS_DRAFT: STATUS_DRAFT,
      currentTransactionId:null,
      showTransactionDetails:false,
      POSSIBLE_HOLDER_ATTRIBUTES:POSSIBLE_HOLDER_ATTRIBUTES
    };
  },

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },

  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
    selectedStatut: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedType: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedOrganization: {
      handler: "getListDebounced",
      immediate: true,
    },
  },

  methods: {
    getWalletHolderName(holder) {
      if (!holder) return "-";
      let name = "";
      this.POSSIBLE_HOLDER_ATTRIBUTES.forEach((attr) => {
        if (holder[attr]) {
          if (name) {
            name += " ";
          }
          name += holder[attr];
        }
      });
      return name || "-";
    },

    resetFilters() {
      this.query = null;
      this.selectedStatut = null;
      this.selectedType = null;
      if(this.showGlobal){
        this.selectedOrganization = null;
      }
    },

    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    viewTransaction(id){
      this.currentTransactionId = id;
      this.showTransactionDetails = true;
    },
    refreshData(status='draft'){
      this.$emit('transactionStatusUpdated', status);
      this.getList();
    },
    async getList() {
      try {
        this.loading = true;
        let params = {
          sort: this.sort,
          "filter.search": this.query,
          "filter.meta.status": this.selectedStatut,
          "filter.meta.payment_gateway": this.paymentGateway,
          "filter.type": this.selectedType,
          "page.number": this.pagination.currentPage,
          "page.size": this.pagination.perPage,
        };
        
        if(this.walletId!=null){
          params.wallet_id = this.walletId;
        }
        
        if(this.selectedOrganization!=null){
          params.holder_type = 'organization';
          params.holder_id = this.selectedOrganization;
        }
        
        
        const response = await this.$axios.get(`/elixio-wallet/transactions`, {
            params,
          });
        this.transactions = response.data.data;
        this.total = response.data.total;
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },

    refreshList() {
      this.getList();
    },
  },
  created() {
    this.getList();
  },
};
</script>